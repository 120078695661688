import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#C468D4", // Set your primary color here
    },
    secondary: {
      main: "#000", // Set your secondary color here
    },
    gray: {
      main: "#6F7071", // Custom color 1
    },
    lightGray: {
      main: "#F3F7FB",
    },
    black: {
      main: "#000",
    },
    red: {
      main: "#FF0000",
    },
    blue: {
      main: "#7581F2",
    },
  },
  typography: {
    fontFamily: "Nunito Sans, sans-serif", // Replace 'Your Custom Font Name' with the name of your custom font
    h1: {
      fontSize: "40px",
      fontWeight: 800, // Set your default font size for h1
    },
    h2: {
      fontSize: "30px",
      fontWeight: 800, // Set your default font size for h2
    },
    h3: {
      fontSize: "22px", // Set your default font size for h3
      fontWeight: "400 !important",
    },
    h6: {
      fontSize: "18px",
      fontWeight: "400 !important",
    },
    link: {
      color: "secondary",
    },
  },
});

theme = responsiveFontSizes(theme, {
  breakpoints: ["sm", "md", "lg"], // Add your desired breakpoints here
  factors: [2, 1.5, 1.1], // Add the desired factors for each breakpoint
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
