import React, { useState } from "react";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import logo from "../Asserts/White_Logo 1.svg"; // Ensure this path is correct
import bg from "../Asserts/Bg.jpg";
import { LocationOn, Email, Phone } from "@mui/icons-material";

const ContactUsForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the mailto URL
    const mailtoLink = `mailto:dev@avvtar.ai?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\n${message}`
    )}`;

    // Open the default email client
    window.location.href = mailtoLink;
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: 4,
        position: "relative",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          width: "150px",
        }}
      />
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "calc(100vh - 100px)" }} // Adjust for logo height
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={5}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 4,
              background: "#fff",
              borderRadius: 2,
              boxShadow: 3,
              width: "100%",
              maxWidth: "500px",
              height: "auto",
            }}
          >
            <Typography variant="h4" gutterBottom align="center">
              Contact Us
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
              />
              <TextField
                label="Subject"
                variant="outlined"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                fullWidth
              />
              <TextField
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={5}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 4,
              background: "#fff",
              borderRadius: 2,
              boxShadow: 3,
              width: "100%",
              maxWidth: "500px",
              textAlign: "left",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ color: "#000" }}
            >
              Reach Us
            </Typography>
            <Box sx={{ marginBottom: 2, width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                  color: "#000",
                }}
              >
                <LocationOn sx={{ fontSize: 24, mr: 1 }} />
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", flex: "0 0 120px" }}
                >
                  Office Address:
                </Typography>
                <Typography
                  component="span"
                  ml={2}
                  mt={2}
                  sx={{ flex: "1", color: "#000" }}
                >
                  L 152, CK PLaza, 14th Cross, 5th Main Rd, Sector 6, HSR
                  Layout, Bengaluru, Karnataka 560102
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ marginBottom: 5, width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  color: "#000",
                }}
              >
                <Email sx={{ fontSize: 24, mr: 1 }} />
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", flex: "0 0 120px" }}
                >
                  Email:
                </Typography>
                <Typography component="span" ml={2} sx={{ flex: "1" }}>
                  <a href="mailto:dev@avvtar.ai" style={{ color: "#4fc3f7" }}>
                    dev@avvtar.ai
                  </a>
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  color: "#000",
                }}
              >
                <Phone sx={{ fontSize: 24, mr: 1 }} />
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", flex: "0 0 120px" }}
                >
                  Direct:
                </Typography>
                <Typography component="span" ml={2} sx={{ flex: "1" }}>
                  <a href="tel:+1234567890" style={{ color: "#4fc3f7" }}>
                    +91 80 50555911
                  </a>
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUsForm;
