// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import WelcomePage from "./components/welcomePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUsForm from "./components/contact-us";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<ContactUsForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
