import React from "react";
import { Container, Typography, Box, Link, Grid } from "@mui/material";
import avvtarLogo from "../Asserts/avvtarai-logo.svg";
import bg from "../Asserts/Bg.jpg";

const PrivacyPolicy = () => {
  return (
    <Grid
      spacing={2}
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          bgcolor: "#f5f5f5",
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Box textAlign="center" mb={4}>
          <img src={avvtarLogo} alt="avvtar.ai" width={200} height={50} />
          <Typography variant="h2" color="primary" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom color="textPrimary">
            <strong>Effective Date:</strong> 01/08/2024
          </Typography>
        </Box>

        <Typography variant="h5" color="primary" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          Welcome to avvtar.ai ("we," "our," or "us"). We are committed to
          protecting your privacy. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you use our mobile
          application (the "App") or Website. Please read this policy carefully.
          If you do not agree with the terms of this Privacy Policy, please do
          not access the App.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          We collect or process only the following types of information:
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          <strong>Device Information:</strong> We collect information about the
          device you use to access the App, including hardware model and
          operating system.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          We use the collected information for the following purposes:
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          - To provide, operate, and maintain the App.
          <br />
          - To improve, personalize, and expand the App.
          <br />
          - To understand and analyze how you use the App.
          <br />
          - To prevent fraudulent activity and ensure the security of your
          information.
          <br />- To comply with legal obligations and enforce our policies.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          We may share your information in the following situations only:
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          <strong>For Legal Reasons:</strong> We may disclose your information
          if required by law, to comply with legal processes, or to protect the
          rights, property, and safety of our users and others.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          5. Data Security
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          We implement reasonable security measures to protect your information
          from unauthorized access, use, or disclosure. However, no data
          transmission over the internet or electronic storage is 100% secure.
          While we strive to protect your personal information, we cannot
          guarantee its absolute security.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          6. Your Choices
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          You can manage your information in the following ways:
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          <strong>Opt-Out:</strong> You can opt out of receiving promotional
          communications, if any, from us by following the instructions in those
          communications.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          7. Children’s Privacy
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          The App is not intended for use by children under the age of 13. We do
          not knowingly collect personal information from children under 13. If
          we learn that we have collected personal information from a child
          under 13, we will delete that information as quickly as possible.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy in the App. You
          are advised to review this Privacy Policy periodically for any
          changes.
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          <Link href="mailto:dev@avvtar.ai" color="inherit">
            dev@avvtar.ai
          </Link>
        </Typography>

        <Typography variant="h5" color="primary" gutterBottom>
          10. Acceptance of This Policy
        </Typography>
        <Typography variant="body1" paragraph color="textPrimary">
          By using the App, you signify your acceptance of this Privacy Policy.
          If you do not agree to this policy, please do not use the App. Your
          continued use of the App following the posting of changes to this
          policy will be deemed your acceptance of those changes.
        </Typography>
      </Container>
    </Grid>
  );
};

export default PrivacyPolicy;
